<template>
	<ClientDetail />
</template>
<script>
import ClientDetail from '@/modules/ClientDetail/index.vue'
export default {
	components: {
		ClientDetail
	}
}
</script>
